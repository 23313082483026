var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "800px", "max-height": "730px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c(
        "div",
        { staticClass: "popup_cont" },
        [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v(_vm._s(_vm.$t("msg.NEWB010P050.001"))),
          ]),
          _c("div", {
            staticClass: "con_desc",
            domProps: { innerHTML: _vm._s(this.$t("msg.NEWB010P050.002")) },
          }),
          _c(
            "e-tab",
            {
              ref: "tab",
              staticClass: "tab t2 mt15",
              attrs: { "page-type": "containerPop", fitable: "" },
            },
            [
              _c(
                "div",
                { staticClass: "content_box mt0 beforetab" },
                [
                  _c(
                    "e-tab-item",
                    { attrs: { title: "GP", name: "gp", selected: true } },
                    [_c("container-tab1")],
                    1
                  ),
                  _c(
                    "e-tab-item",
                    { attrs: { title: "RF", name: "rf" } },
                    [_c("container-tab2")],
                    1
                  ),
                  _c(
                    "e-tab-item",
                    { attrs: { title: "OT", name: "ot" } },
                    [_c("container-tab3")],
                    1
                  ),
                  _c(
                    "e-tab-item",
                    { attrs: { title: "FR", name: "fr" } },
                    [_c("container-tab4")],
                    1
                  ),
                  _c(
                    "e-tab-item",
                    { attrs: { title: "SR", name: "sr" } },
                    [_c("container-tab5")],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }