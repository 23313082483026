<template>
  <div class="popup_wrap" style="width:800px;max-height: 730px;">
    <!--  popup_wrap -->
    <button
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">{{ $t('msg.NEWB010P050.001') }}</h1><!-- 컨테이너 정보 -->
      <div class="con_desc" v-html="this.$t('msg.NEWB010P050.002')"></div><!-- 고려해운의 컨테이너 정보를 열람하실 수 있습니다.<br>(Max playload와 당사가 허용하는 최대 중량은 상이 하오니 참조바랍니다.) -->
      <e-tab
        ref="tab"
        class="tab t2 mt15"
        :page-type="'containerPop'"
        fitable
      >
        <div class="content_box mt0 beforetab">
          <e-tab-item
            title="GP"
            name="gp"
            :selected="true"
          >
            <container-tab1></container-tab1>
          </e-tab-item>
          <e-tab-item
            title="RF"
            name="rf"
          >
            <container-tab2></container-tab2>
          </e-tab-item>
          <e-tab-item
            title="OT"
            name="ot"
          >
            <container-tab3></container-tab3>
          </e-tab-item>
          <e-tab-item
            title="FR"
            name="fr"
          >
            <container-tab4></container-tab4>
          </e-tab-item>
          <e-tab-item
            title="SR"
            name="sr"
          >
            <container-tab5></container-tab5>
          </e-tab-item>
        </div><!--content_box -->
      </e-tab>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import ETabItem from '@/components/common/ETabItem'
import ETab from '@/components/common/ETab'

export default {
  name: 'ContainerPop',
  components: {
    ETabItem,
    ETab,
    'container-tab1': () => import('@/pages/trans/popup/ContainerDry'),
    'container-tab2': () => import('@/pages/trans/popup/ContainerRF'),
    'container-tab3': () => import('@/pages/trans/popup/ContainerOT'),
    'container-tab4': () => import('@/pages/trans/popup/ContainerFR'),
    'container-tab5': () => import('@/pages/trans/popup/ContainerSR')
  }
}
</script>

<style scoped>

</style>
